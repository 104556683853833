<template>
<v-card>
  <v-card-title>
    Eventi
  </v-card-title>
  <v-card-text>
    <v-list two-line>
      <v-list-item v-for="item in items" :key="item.PatientEventId || item.UniqueEmailId">
        <template v-if="item.PatientEventId">
        <v-list-item-avatar>
          <v-icon :color="IconColor[item.EventName]">{{ IconName[item.EventName] }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
        <v-list-item-title>   {{item.EventName}}
        <v-btn color="primary" small v-if="item.BookingId" :to="'/Admin/Bookings?BookingId=' + item.BookingId">{{item.BookingId}}</v-btn>
        </v-list-item-title>
        <v-list-item-subtitle>   {{item.EventDate | formatDateTime}}
          <span v-if="item.count">{{ item.count }} messaggi</span></v-list-item-subtitle>
      </v-list-item-content>
        </template>
        <template v-if="item.UniqueEmailId">
          <v-list-item-avatar>
            <v-icon :color="EmailDeliveryStatus[item.LastEvent]?.color">{{ EmailDeliveryStatus[item.LastEvent]?.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
            {{item.Subject}}
            </v-list-item-title>
            <v-list-item-subtitle>   {{EmailDeliveryStatus[item.LastEvent]?.description}} -  {{item.SentTime | formatDateTime}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
  </v-card-text>
</v-card>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import moment from "moment";

export default {
	name: "PatientEventsViewer",
	props: {
		// PatientUserId: {
		// 	type: String,
		// 	required: true
		// }
	},
	data() {
		return {
			IconName: {
				ChatEngagement: "fas fa-fw fa-comment-alt-dots",
				PayVisit: "fab fa-fw fa-stripe",
				TallyLead: "fas fa-fw fa-chart-line", // Suggesting a chart or tally icon
				Verify: "fas fa-fw fa-check-circle", // An icon for verification or checking
				Assign: "fas fa-fw fa-user-plus", // An icon representing the addition of a user
				Unassign: "fas fa-fw fa-user-minus", // An icon representing the removal of a user
				BookFreeIntroduction: "fas fa-fw fa-calendar-alt", // An icon for booking or calendar
				ModifyFreeIntroduction: "fas fa-fw fa-edit", // An icon for modifying or editing
				CompleteFreeIntroduction: "fas fa-fw fa-check", // An icon for completion
				CancelFreeIntroduction: "fas fa-fw fa-times", // An icon for cancellation
				CreateVisit: "fas fa-fw fa-notes-medical", // An icon for medical notes or creating visits
				CompleteVisit: "fas fa-fw fa-check-double", // Another icon for completion with emphasis
				ModifyVisit: "fas fa-fw fa-edit", // An icon for modifying or editing
				CancelVisit: "fas fa-fw fa-times", // An icon for cancellation
				VisitExpired: "fas fa-fw fa-hourglass-end" // An icon for expiration or end of time
			},

			IconColor: {
				ModifyFreeIntroduction: "warning",
				CompleteFreeIntroduction: "success",
				CancelFreeIntroduction: "error",
				CompleteVisit: "success",
				ModifyVisit: "warning",
				CancelVisit: "error",
				VisitExpired: "error"
			},

			EmailDeliveryStatus: {
				dropped: {
					icon: "fas fa-times-circle",
					description: "Dropped",
					color: "error"
				},
				processed: {
					icon: "far fa-hourglass",
					description: "Processed",
					color: "primary"
				},
				NULL: {
					icon: "fas fa-question-circle",
					description: "Unknown/Null",
					color: "warning"
				},
				click: {
					icon: "fas fa-mouse-pointer",
					description: "Click",
					color: "success"
				},
				delivered: {
					icon: "fas fa-envelope",
					description: "Delivered",
					color: "success"
				},
				deferred: {
					icon: "fas fa-exclamation-circle",
					description: "deferred",
					color: "warning"
				},
				bounce: {
					icon: "fas fa-undo",
					description: "Bounce",
					color: "error"
				},
				spamreport: {
					icon: "fas fa-exclamation-triangle",
					description: "Spam Report",
					color: "error"
				},
				open: {
					icon: "fas fa-envelope-open",
					description: "Open",
					color: "success"
				}
			},
			items: []
		}
	},
	computed: {
		PatientUserId() {
			return this.$route.params.PatientUserId;
		}
	},
	created() {
		this.PatientEventsService = new CrudClient("PatientEvents");
	},
	async mounted() {
		await this.LoadPatientData();
	},
	methods: {

		async LoadPatientData() {
			const response = await this.PatientEventsService.Get(`Patient/${this.PatientUserId}`);

			const sentEmails = await this.PatientEventsService.Get(`PatientSentEmails/${this.PatientUserId}`);
			// this.$log.debug(sentEmails);

			// iterate in response.Data, remove the consecutive items with EventName = ChatEngagement in the same day and add count to the object
			const filteredData = [];

			let lastChatEngagementEvent = null;
			for (let i = 0; i < response.Data.length; i++) {
				const currentItem = response.Data[i];
				if (currentItem.EventName === "ChatEngagement") {
					if (lastChatEngagementEvent) {
						lastChatEngagementEvent.count++
					} else {
						lastChatEngagementEvent = currentItem;
						lastChatEngagementEvent.count = 1;
						filteredData.push(lastChatEngagementEvent);
					}
				} else {
					lastChatEngagementEvent = null;
					filteredData.push(currentItem)
				}
			}

			// let lastEventDate = null;
			// let lastChatEngagementItem = null;

			// response.Data.forEach(item => {
			// 	const currentItemDate = moment(item.EventDate).format("YYYY-MM-DD");
			// 	if (item.EventName === "ChatEngagement") {
			// 		if (currentItemDate === lastEventDate) {
			// 			if (lastChatEngagementItem) {
			// 				lastChatEngagementItem.count++;
			// 			}
			// 			return;
			// 		} else {
			// 			lastChatEngagementItem = item;
			// 			item.count = 1;
			// 		}
			// 	} else {
			// 		lastChatEngagementItem = null;
			// 	}
			// 	filteredData.push(item);
			// 	lastEventDate = currentItemDate;
			// });

			// merge and sort values
			// sort [...filteredData, ...sentEmails] for date, for filtered data take EventDate and for sentEmail SentTime
			this.items = [...filteredData, ...sentEmails].sort((a, b) => {
				const dateA = "EventDate" in a ? moment(a.EventDate) : moment(a.SentTime);
				const dateB = "EventDate" in b ? moment(b.EventDate) : moment(b.SentTime);
				return dateA - dateB;
			});
		}
	}

}
</script>

<style scoped lang="scss">

</style>
