<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<h1 class="nutriyou-h1">{{ $t("nutriyou.bookings") }}</h1>

		<v-dialog v-model="showNewBookingDialog">
			<CreateBookingCard @bookingCreated="bookingCreated"></CreateBookingCard>
		</v-dialog>

		<v-container fluid>
			<v-row dense>
				<v-col cols="6" sm="3">
					<v-btn @click="showNewBookingDialog = true" color="primary" large>Nuovo appuntamento</v-btn>
				</v-col>
				<v-col cols="6" sm="3">
					<v-text-field label="Booking Id" v-model="filterByBookingId" type="number" clearable @change="LoadBookings(true)"></v-text-field>
				</v-col>
				<v-col cols="6" sm="3">
	<v-select v-model="filterByDate" :items="filterByDateOptions" item-text="Name" item-value="Value"
								@change="orderBy = 'BookingTime'; LoadBookings(true)" label="Data"
								prepend-icon="fas fa-calendar-alt">
							</v-select>

				</v-col>
				<v-col cols="6" sm="3"><v-select v-model="orderBy" :items="orderByItems" item-text="Name" item-value="Value"
							@change="LoadBookings(true)" label="Ordina"
							prepend-icon="fas fa-sort-alt">
						</v-select></v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6" sm="3">
					<ClientAccountPicker RoleName="Nutritionist"
						:label="$t('nutriyou.nutritionist')"
						v-model="filterNutritionistUserId"
						@change="LoadBookings(true)">
					</ClientAccountPicker>
				</v-col>
				<v-col cols="6" sm="3">
						<!-- <ClientAccountPicker RoleName="Patient"
							:label="$t('nutriyou.patient')"
							v-model="filterPatientUserId"
							@change="LoadBookings(true)">
						</ClientAccountPicker> -->
						<PatientPicker v-model="filterPatientUserId" :label="$t('nutriyou.patient')" :IsAdmin="true"
							@change="LoadBookings(true)"></PatientPicker>
					</v-col>
				<v-col cols="6" sm="3">
						<v-select v-model="filterByBookingType" multiple :items="filterByBookingTypeItems" item-text="Name"
							item-value="Value" @change="LoadBookings(true)" label="Servizio" clearable>
						</v-select>

					</v-col>
				<v-col cols="6" sm="3">
					<v-select v-model="filterByStatus" multiple :items="filterByStatusItems" item-text="Name"
						item-value="Value" @change="LoadBookings(true)" label="Stato appuntamento" clearable>
					</v-select>

				</v-col>

			</v-row>
		</v-container>
		<skeleton-table v-if="LoadingData" :columns="4" :actions="1"></skeleton-table>
		<div v-else>
			<BookingDetailsCard v-for="booking in items" :key="booking.BookingId" :booking="booking"
				@bookingModified="LoadBookings"></BookingDetailsCard>
			<div class="text-center">
				<v-pagination v-if="itemsPaging" v-model="currentPage" :length="itemsPaging.TotalPages"
					:total-visible="5"></v-pagination>
			</div>
		</div>

	</div>
</template>
<script>
import moment from "moment";
import CrudClient from "@/services/CrudClient/";
import CreateBookingCard from "./components/CreateBookingCard.vue";
import BookingDetailsCard from "./components/BookingDetailsCard"
import ClientAccountPicker from "@/components/Account/ClientAccountPicker";
import PatientPicker from "@/views/Nutritionists/components/PatientPicker"

import { mapActions } from "vuex";
export default {
	metaInfo() {
		return {
			title: this.$t("nutriyou.bookings"),
			meta: [{ name: "description", content: this.$t("nutriyou.bookings") }],
		};
	},

	components: {
		CreateBookingCard,
		BookingDetailsCard,
		ClientAccountPicker,
		PatientPicker
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
			],

			FilterByPatientUserId: null,
			FilterByNutritionistUserId: null,
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 10,
			showNewBookingDialog: false,
			filterByBookingId: null,
			filterByDate: null,
			filterByDateOptions: [
				{ Name: "-", Value: null },
				{ Name: "Ieri", Value: "D-1" },
				{ Name: "Oggi", Value: "D" },
				{ Name: "Domani", Value: "D+1" },
				{ Name: "Prossimi 7 giorni", Value: "Next7" },
				{ Name: "Settimana Scorsa", Value: "W-1" },
				{ Name: "Questa settimana", Value: "W" },
				{ Name: "Settimana Prossima", Value: "W+1" },
				{ Name: "Futuri", Value: "F" },
				{ Name: "Passati", Value: "P" },
			],

			orderBy: "BookingTime:desc",
			orderByItems: [
				{ Name: "BookingId", Value: "BookingId" },
				{ Name: "BookingId:desc", Value: "BookingId:desc" },
				{ Name: "BookingTime", Value: "BookingTime" },
				{ Name: "BookingTime:desc", Value: "BookingTime:desc" },
			],

			filterByStatus: [],
			filterByStatusItems: [
				{ Name: "Prenotato", Value: 1 },
				{ Name: "Completato", Value: 2 },
				{ Name: "Annullato", Value: 3 },
				{ Name: "In attesa di pagamento", Value: 4 }
			],
			filterByBookingType: [],
			filterByBookingTypeItems: [
				{ Name: "Incontro Conoscitivo", Value: 1 },
				{ Name: "Visita Nutrizionale", Value: 2 },
				{ Name: "Consegna e spiegazione del piano", Value: 3 },
			],
			filterNutritionistUserId: null,
			filterPatientUserId: null

			// Booked	1
			// Completed	2
			// Cancelled	3
			// Waiting for Payment	4
			// Cancelled with fee	5
		};
	},

	watch: {
		async currentPage() {
			await this.LoadBookings();
		},
	},

	created() {
		this.BookingsService = new CrudClient("Bookings");
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.bookings"),
			disabled: true,
			exact: true,
			to: "/Admin/Bookings",
		});

		if (this.$route.query.BookingId) {
			this.filterByBookingId = this.$route.query.BookingId;
		}

		await this.LoadBookings();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async bookingCreated() {
			this.showNewBookingDialog = false;
			await this.LoadBookings();
		},

		async LoadBookings(resetPaging) {
			if (resetPaging) {
				this.currentPage = 1;
			}

			let filter;
			if (this.filterByStatus.length) {
				filter = "BookingStatusId:in:" + this.filterByStatus.join(",");
			}

			if (this.filterByBookingType.length) {
				if (filter) filter += ";";
				else filter = "";

				filter += "BookingTypeId:in:" + this.filterByBookingType.join(",");
			}

			if (this.filterByBookingId) {
				if (filter) filter += ";";
				else filter = "";

				filter += "BookingId:" + this.filterByBookingId;
			}

			if (this.filterNutritionistUserId) {
				if (filter) filter += ";";
				else filter = "";

				filter += "NutritionistUserId:" + this.filterNutritionistUserId;
			}

			if (this.filterPatientUserId) {
				if (filter) filter += ";";
				else filter = "";

				filter += "PatientUserId:" + this.filterPatientUserId;
			}

			if (this.filterByDate) {
				if (filter) filter += ";";
				else filter = "";

				const currentDataTime = new Date();
				let start = null;
				let end = null;

				switch (this.filterByDate) {
					case "D":
						start = moment(currentDataTime).format("YYYY-MM-DD");
						end = moment(currentDataTime).add(1, "days").format("YYYY-MM-DD");
						break;
					case "D+1":
						start = moment(currentDataTime).add(1, "days").format("YYYY-MM-DD");
						end = moment(currentDataTime).add(2, "days").format("YYYY-MM-DD");
						break;
					case "D-1":
						start = moment(currentDataTime).add(-1, "days").format("YYYY-MM-DD");
						end = moment(currentDataTime).add(0, "days").format("YYYY-MM-DD");
						break;

					case "W":
						start = moment(currentDataTime).startOf("isoWeek").format("YYYY-MM-DD");
						end = moment(currentDataTime).startOf("isoWeek").add(7, "days").format("YYYY-MM-DD");
						break;
					case "W-1":
						start = moment(currentDataTime).startOf("isoWeek").add(-7, "days").format("YYYY-MM-DD");
						end = moment(currentDataTime).startOf("isoWeek").add(0, "days").format("YYYY-MM-DD");
						break;
					case "W+1":
						start = moment(currentDataTime).startOf("isoWeek").add(7, "days").format("YYYY-MM-DD");
						end = moment(currentDataTime).startOf("isoWeek").add(14, "days").format("YYYY-MM-DD");
						break;
					case "Next7":
						start = moment(currentDataTime).add(1, "days").format("YYYY-MM-DD");
						end = moment(currentDataTime).add(8, "days").format("YYYY-MM-DD");
						break;
				}

				if (start != null) {
					filter += "BookingTime:between:" + start + "," + end;
				} else {
					switch (this.filterByDate) {
						case "F":
							start = moment(currentDataTime).format("YYYY-MM-DD");

							filter += `BookingTime:gt:${start}`;
							break;
						case "P":
							start = moment(currentDataTime).format("YYYY-MM-DD");

							filter += `BookingTime:lt:${start}`;
							break;
					}
				}
			}

			try {
				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*, BookingCancelReason.Name",
					filter: filter,
					orderBy: this.orderBy
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":{
		"": ""
	}
}
</i18n>
