<template>
<v-card>
  <v-card-title>Rispondi</v-card-title>
  <v-card-text>
    <v-textarea label="Messaggio" v-model="message" auto-grow rows="2"></v-textarea>

  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="$emit('close')">{{$t("common.cancel")}}</v-btn>
    <v-btn color="primary"
           :loading="sendingMessage"
           @click="sendWhatsappMessage">{{ $t("common.reply")}}</v-btn>
  </v-card-actions>
</v-card>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";

export default {
	name: "WhatsappReplyMessage",
	data() {
		return {

			message: "",
			sendingMessage: false,
		}
	},
	props: {
		phoneNumber: {
			type: String,
			required: true
		}
	},
	created() {
		this.WhatsappWebhookService = new CrudClient("TwilioWhatsappWebhooks");
	},
	mounted() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		convertWhatsAppToPhoneNumber(whatsappNumber) {
			// Check if the input starts with 'whatsapp:' and remove it
			if (whatsappNumber.startsWith("whatsapp:")) {
				return whatsappNumber.substring(9);
			}
			return whatsappNumber;
		},

		async sendWhatsappMessage() {
			try {
				this.sendingMessage = true;

				await this.WhatsappWebhookService.Post(null, {
					Body: this.message,
					PhoneNo: this.convertWhatsAppToPhoneNumber(this.phoneNumber),

				}, "ReplyMessage");

				this.snackSuccess({ Text: this.$t("common.success.messageSent") });
				this.$emit("messageSent");
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotSendMessage") });
				this.$captureError(error);
			} finally {
				this.sendingMessage = false;
			}
		}
	}
}
</script>

<style scoped lang="scss">

</style>
