<template>
<div>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-switch label="Messaggi ricevuti" v-model="conversationsWithReceivedMessages" @change="LoadConversations"></v-switch>
        <v-list>
          <v-list-item @click="showNewMessageDialog = true" class="blue lighten-2">
            <v-list-item-title>
              <v-icon class="mr-2">fas fa-plus</v-icon>Nuovo messaggio
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="item in conversations" :key="item.PhoneNo"  three-line
          @click="onSelectConversation(item)"
          :class="item.PhoneNo === selectedConversation?.PhoneNo ?
          'nyGreen lighten-1' : 'grey lighten-3'"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="item.MessageDirection === 'Received'">fas fa-arrow-left</v-icon>
                <v-icon v-else>fas fa-arrow-right</v-icon>

                {{ item.PhoneNo}} </v-list-item-title>
              <v-list-item-subtitle>
                {{item.Body}}

                <div class="grey--text" style="font-size: 0.7rem">{{item.MessageTimeUTC	 | formatLocalDateTime}}</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-pagination v-if="conversationPaging"
          v-model="conversationsCurrentPage"
          :length="conversationPaging.TotalPages"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="6">
        <h3 class="text-h5 mb-2">Messaggi</h3>
        <v-progress-linear v-if="loadingMessage" indeterminate color="primary"></v-progress-linear>
        <v-btn  color="primary" class="ma-2" large :to='"/Users/EditUser/" + selectedConversation.RelatedUserId' v-if="selectedConversation && selectedConversation.RelatedUserId"
        >
          <v-icon left>fas fa-user</v-icon>
          {{ selectedConversation.DisplayName}} {{ selectedConversation.EmailIdentifier}}
        </v-btn>
        <div v-for="item in items" :key="item.WhatsappMessageId">
          <WhatsappMessageViewer :item="item" @messageSent="LoadMessages"></WhatsappMessageViewer>
        </div>

      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="showNewMessageDialog" max-width="500px">
    <WhatsappSendMessageFromTemplate @messageSent="LoadMessages"></WhatsappSendMessageFromTemplate>
  </v-dialog>

</div>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import WhatsappMessageViewer from "@/views/Admin/components/Whatsapp/WhatsappMessageViewer.vue";
import WhatsappSendMessageFromTemplate from "./components/Whatsapp/WhatsappSendMessageFromTemplate";
import { mapActions } from "vuex";

export default {
	name: "WhatsappMessages.vue",
	components: {
		WhatsappMessageViewer,
		WhatsappSendMessageFromTemplate
	},
	data() {
		return {
			loadingMessage: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 20,

			conversationsWithReceivedMessages: true,

			conversations: [],
			conversationPaging: null,
			conversationsCurrentPage: 1,
			conversationsPageSize: 10,

			selectedConversation: null,
			showNewMessageDialog: false

		}
	},
	watch: {

		async conversationsCurrentPage() {
			await this.LoadConversations();
		},
	},
	created() {
		this.WhatsappMessagesService = new CrudClient("WhatsappMessages");
	},
	async mounted() {
		await this.LoadConversations();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadConversations() {
			const res = await this.WhatsappMessagesService.GetPaged({
				limit: this.pageSize,
				skip: (this.conversationsCurrentPage - 1) * this.conversationsPageSize,
				fields: "*, TwilioWhatsappMessageMedia.*",
				filter: "ConversationsWithReceivedMessages:" + this.conversationsWithReceivedMessages,
				urlPostFix: "Conversations"
			});

			this.conversations = res.Data;
			this.conversationPaging = res.Paging;
		},
		async onSelectConversation(item) {
			this.selectedConversation = item;

			await this.LoadMessages();
		},
		async fetchMessages(urlPostFix) {
			const res = await this.WhatsappMessagesService.GetPaged({
				limit: this.pageSize,
				skip: (this.currentPage - 1) * this.pageSize,
				fields: "*, TwilioWhatsappMessageMedia.*",
				urlPostFix: urlPostFix
			});
			this.items = res.Data;
			this.itemsPaging = res.Paging;
		},

		async LoadMessages() {
			try {
				this.loadingMessage = true;
				// Web api cannot get encoded url parameters containing +
				// removing special characters is the workaround. see server side implementation
				const urlPostFix = `ConversationMessages/PhoneNo/${(this.selectedConversation.PhoneNo).replace(":+", "")}`;
				// }

				await this.fetchMessages(urlPostFix);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingMessage = false;
			}
		},

		visibilityChange() {

		}

	}
}
</script>
