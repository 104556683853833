<template>
<div class="mb-4">

  <div class="poppins-regular rounded pa-3" style="font-size: 1rem"
  :class="{ 'blue-grey lighten-5 ml-8': item.From !== defaultSender,
            'nyGreen lighten-1 mr-8': item.From === defaultSender }"
  ><span
    v-html="item.Body.replace(/\n/g, '<br />')"></span>

    <div class="text-right" style="font-size: 0.8rem">
      {{item.MessageTimeUtc | formatLocalDateTime}}</div>
  </div>

  <div v-if="item.TwilioWhatsappMessageMedia.length">
    <div class="mb-3">
    <v-icon>fas fa-paperclip</v-icon> Allegati</div>
    <v-container fluid>
      <v-row>
        <v-col cols="3" v-for="media in item.TwilioWhatsappMessageMedia" :key="media.MediaId" class="grey lighten-2 rounded">

          <a :href="media.MediaUrl" target="_blank">
            <v-img
              v-if="media.MediaContentType.includes('image')"
              :src="media.MediaUrl" :alt="media.MediaContentType" height="200" contain></v-img>
            <div v-else>
              {{ media.MediaContentType}}
              {{ media.MediaUrl}}
            </div>
          </a>

        </v-col>
      </v-row>
    </v-container>

  </div>

  <div v-if="item.To === defaultSender" class="my-2 text-right">

      <v-btn color="primary" large
           v-if="showReplyDialog === false"
           :disabled="hasHoursPassed(item.MessageTimeUtc, 24)"
            @click="showReplyDialog=true">Rispondi
      </v-btn>
      <WhatsappReplyMessage
        :phoneNumber="item.From"
        v-if="showReplyDialog"
        @sentMessage="OnSentMessage"
        @close="showReplyDialog=false"
      ></WhatsappReplyMessage>
    </div>
</div>
</template>
<script>
import WhatsappReplyMessage from "@/views/Admin/components/Whatsapp/WhatsappReplyMessage.vue";
import moment from "moment/moment";
import { mapActions } from "vuex";

export default {
	name: "WhatsappMessageViewer",
	components: { WhatsappReplyMessage },
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			defaultSender: "whatsapp:+393314045459",
			showReplyDialog: false,
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		hasHoursPassed(utcDate, hours) {
			const initialDate = moment.utc(utcDate); // Parse the initial UTC date
			const currentDate = moment.utc(); // Get the current UTC date

			const elapsedHours = currentDate.diff(initialDate, "hours"); // Calculate the elapsed hours

			return elapsedHours >= hours;
		},
		OnSentMessage() {
			this.showReplyDialog = false;
			this.$emit("sentMessage");
		}
	}
}
</script>

<style scoped lang="scss">

</style>
