<template>
  <v-card>
    <v-card-title>
      Nuovo Messaggio
    </v-card-title>
    <v-card-text>

      <div v-if="selectedTemplate">
        <span v-if="selectedTemplate.ApprovalRequest.Status === 'approved'">
          <v-icon color="success">fas fa-check-circle</v-icon> Approvato
        </span>
        <span v-else>
          {{selectedTemplate.ApprovalRequest.Status }} -
          {{selectedTemplate.ApprovalRequest.RejectionReason }}
        </span>

      </div>

      <v-text-field v-model="userPhoneNumber"
      label="Numero di telefono (formato intenrazionle) +39xxxxx"></v-text-field>

      <v-select :items="ContentTemplates"
                item-text="Name"
                item-value="ContentTemplateSid"
                v-model="selectedTemplateSid"
                @change="resetVariables"
                label="Seleziona Template">

      </v-select>

      <v-textarea v-if="selectedTemplateSid"
          readonly
        :value="selectedTemplate.Body">
      </v-textarea>

      {{templateVariables}}
      <h4>Variabili</h4>
      <div v-for="variable in templateVariables" :key="variable">
        <v-text-field :label="variable" v-model="parameters[variable]"></v-text-field>
      </div>

    </v-card-text>
    <v-card-actions>
      <v-btn @click="sendMessage" :loading="sendingMessage" color="primary" :disabled="!canSendMessage">Send</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";

export default {
	name: "WhatsappMessages",
	data() {
		return {
			sendingMessage: false,
			ContentTemplates: [],
			selectedTemplateSid: null,
			userPhoneNumber: "+393335452237",
			parameters: {}
		}
	},
	computed: {
		selectedTemplate() {
			if (!this.selectedTemplateSid) {
				return null
			}
			return this.ContentTemplates.find(c => c.ContentTemplateSid === this.selectedTemplateSid);
		},

		templateVariables() {
			if (!this.selectedTemplateSid) { return null }

			return this.getVariablesFromTemplate(this.selectedTemplate.Body)
		},

		canSendMessage() {
			if (!this.selectedTemplate) { return false; }

			if (this.selectedTemplate.ApprovalRequest.Status !== "approved") { return false; }

			if (this.templateVariables.length > 0) {
				for (let i = 0; i < this.templateVariables.length; i++) {
					if (!this.parameters[this.templateVariables[i]] || this.parameters[this.templateVariables[i]] === "") {
						return false;
					}
				}
			}

			return true;
		}
	},
	created() {
		this.WhatsappWebhookService = new CrudClient("TwilioWhatsappWebhooks");
	},
	async mounted() {
		this.ContentTemplates = await this.WhatsappWebhookService.Get(null, "ContentTemplates")
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getVariablesFromTemplate(template) {
			const regex = /{{(.*?)}}/g;
			const variables = [];
			let match;
			while ((match = regex.exec(template)) !== null) {
				variables.push(match[1].trim());
			}
			return variables;
		},

		resetVariables() {
			this.parameters = {};
		},

		async sendMessage() {
			/* string ContentTemplateSid, string PhoneNo, Dictionary<string, string> TemplateVariables */
			try {
				this.sendingMessage = true;

				await this.WhatsappWebhookService.Post(null, {
					ContentTemplateSid: this.selectedTemplateSid,
					PhoneNo: this.userPhoneNumber,
					TemplateVariables: this.parameters
				}, "SendMessage");

				this.snackSuccess({ Text: this.$t("common.success.messageSent") });
				this.$emit("messageSent");
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotSendMessage") });
				this.$captureError(error);
			} finally {
				this.sendingMessage = false;
			}
		}
	}
}
</script>
<style scoped lang="scss">

</style>
